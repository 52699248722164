import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { Route, Link, Switch, BrowserRouter as Router } from 'react-router-dom'
import Config from '../Config';
import Auth from './Auth';

import Menu from './Menu';
import Login from '../home/Login';
import Registration from '../home/Registration';
import Cart from '../profile/Cart';
import Home from '../home/Home';
import About from '../about/About';
import News from '../about/News';
import $ from 'jquery'
import TermsConditions from '../about/TermsConditions';
import PrivacyPolicy from '../about/PrivacyPolicy';
import Game from '../games/Games'
import Disclaimer from '../about/Disclaimer'
import HowToPlay from '../about/HowToPlay'
import Faqs from '../about/Faqs'
import Contact from '../contact/Contact'
import Result from '../results/Results'
import Profile from '../profile/Profile'

/**
 * 
 * Header template for all the pages
 * 
 */
export default class Header extends Component {

  constructor() {
    super()

    this.menu = new Menu()
    this.auth = new Auth()
    this.config = new Config()
    this.$http = this.config.DConnect()

    this.state = {
      menus: this.menu, livegames: [],
      games: [], cart: []
    }
  }

  componentDidMount() {
    this.getGames();
  }

  doLogout = () => {
    this.auth.logout()
        window.location.reload(true)
    
    //return
  }

  getGames = () => {
    // this.$http.get('web/web/gamedetails/1')
    // .then(res => {
    //   let livegames = this.auth.decryptData(res.data);

    //   let livegames = res.data.filter(rdata => [3,4,5,6,7,8,9,20].includes(rdata.gamecode));

    //   this.$http.get('web/web/game')
    //   .then(res => {
    //     let games = this.auth.decryptData(res.data)

    //     this.setState({ games, livegames })
    //     this.getCart()
    //     this.showMenu()
    //   }).catch((error) => { console.log(error.message) })
    // })
  }

  getCart = () => {
    let cart = (this.auth.getCart() == null) ? [] :this.auth.getCart()
    
    this.setState({ cart })
  }

  showMenu = () => {
    let menu = this.state.menus.map(mn => (
      <li id={mn.code} className={(mn.code == 1) ? 'active ' : (mn.submenu !== undefined) ? ' menu_has_children' : ''}>
        <a href="javascript:void(0)" onClick={mn.submenu === undefined ? () => this.props.navClickHandler(mn.code) : ''}>
          <span className="menu-item-parent">{mn.name}</span>
        </a>
        {mn.submenu !== undefined && this.doSubmenu(mn)}
      </li>
    ))
    //return(ReactDOM.render(menu, document.getElementById('navCon')))
  }

  doSubmenu = (menu) => {
    let games = this.state.games
    let submenu = menu.submenu

    if (menu.code == 3) {
      submenu = []
      if (games[0] !== undefined) {
        games.map(gm => {
          let code = '3' + gm.id
          submenu.push({ 'code': code, 'name': gm.gamename })
        })
      }
    }

    return (
      <ul class="sub-menu">
        {submenu.map(smenu => (
          <li>
            <a href="javascript:void(0)" onClick={() => this.props.navClickHandler(smenu.code)}>
              {smenu.name}
            </a>
          </li>
        ))}
      </ul>
    )
  }

  // showCart = () => {
  //   return(ReactDOM.render(<Cart />, document.getElementById('content')))
  // }
  profile = () => {
    //window.location.reload(true)
  }

  render() {
    let games = this.state.games

    return (

      <div>
        <div id="preloader"></div>

        <header className="header-section">
          <div className="header-top">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-6 col-md-6">
                  <div className="header-top-left d-flex">
                    <div className="language-part">
                      <i className="fa fa-globe"></i>
                      <select>
                        <option>Eng</option>
                        <option>Fr</option>
                      </select>
                    </div>
                    <div className="support-part">
                      <a href="tel:233302232143"><i className="fa fa-phone"></i>Support</a>
                    </div>
                    <div className="email-part">
                      {/* <a href="mailto:vision2000lotto@gmail.com"><i className="fa fa-envelope"></i>vision2000lotto@gmail.com</a> */}
                      <a href="mailto:visionlotto@gmail.com"><i className="fa fa-envelope"></i>visionlotto@gmail.com</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-none d-md-block">
                  <div className="header-top-right">
                    <div className="header-cart-count">
                      {/*<Link to="/cart"><span>
                        <i className="fa fa-ticket"></i> My ticket ( {this.state.cart.length} bets )</span></Link>*/}

                      {(this.auth.isAuth()) &&
                       <>
                          {/* <a className="dropdown">
                            <a className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user"></i> Profile</a>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{top:'24px'}}>
                              
                            </div>
                          </a> */}
                          <span><a className="dropdown-item" href="/profile"><i className="fa fa-list"></i> My Details</a></span>
                          <span><a className="dropdown-item" href="#" onClick={this.doLogout}><i className="fa fa-sign-out"></i> Logout</a></span>
                        </>}
                      {(!this.auth.isAuth()) &&
                        <a href='#' data-toggle="modal" data-target="#loginModal">
                          <span><i className="fa fa-user"></i> Login</span>
                        </a>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bottom">
            <div className="container">
              <nav className="navbar navbar-expand-xl">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="menu-toggle"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{zIndex:'1000'}}>
                  <ul id="navCon" className="navbar-nav main-menu ml-auto">

                    <li className="menu-item-parent text">
                      <Link to="/" >Home</Link>
                    </li>
                    <li className="menu-item-parent">
                      <Link to="/result" >Result</Link>
                    </li>
                    <li className="menu_has_children">
                      <Link to="#" >Game</Link>
                      <ul class="sub-menu">
                        {this.state.livegames.map(lg =>{
                          let gm = games.filter(game => game.id === lg.gamecode && game.showOnWeb == 1)
                          if(gm[0] !== undefined){
                            return (
                              <li><Link to={"/game/" + gm[0].id + "/" + gm[0].gamename.replace(' ', "_") + "/"}>{gm[0].gamename}</Link></li>
                              )
                          }
                        })}
                      </ul>
                    </li>
                    <li className="menu_has_children">
                      <Link to="#" >About</Link>
                      <ul class="sub-menu">
                        <li><Link to="/about">Vision Lotto </Link></li>
                        <li><Link to="/news">News </Link></li>
                        <li><Link to="/how-to-play">How to play </Link></li>
                        <li><Link to="/faqs">FAQ </Link></li>
                        <li><Link to="/disclaimer">Disclaimer </Link></li>
                        <li><Link to="/privacy_policy">Privacy Policy</Link></li>
                        <li><Link to="/terms_conditions">Terms &amp; Conditions </Link></li>
                      </ul>
                    </li>
                    <li className="menu-item-parent">
                      <Link to="/contact" >Contact</Link>
                    </li>
                  </ul>
                  
                    <div className={`header-join-part ${this.auth.isAuth() && 'mr-5 pr-5'}`} style={{}}>
                      {(!this.auth.isAuth()) &&
                        <button type="button" className="cmn-btn" data-toggle="modal" data-target="#registrationModal">join us</button>
                      }

                    </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <Switch>
          <Route exact path="/" component={Result} />
          <Route path="/about" component={About} />
          <Route path="/result" component={Result} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/how-to-play" component={HowToPlay} />
          <Route path="/faqs" component={Faqs} />
          <Route path="/terms_conditions" component={TermsConditions} />
          <Route path="/privacy_policy" component={PrivacyPolicy} />
          <Route path="/news" component={News} />
          <Route path="/cart" component={Cart} />
          <Route path="/contact" component={Contact} />
          <Route path="/game/:gameid/:gamename/" component={Game} />
          {(this.auth.isAuth()) ?
            <Route path="/profile" component={Profile} /> :
            <Route path="/profile" component={Home} />
          }
          <Route path="/home" component={ this.doLogout} />
          <Route path="/" component={Home} />
        </Switch>
        <Registration />
        <Login />


      </div>
    )
  }
}