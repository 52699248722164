import React, { Component } from "react";
import Auth from "../common/Auth";
import Config from "../Config";
import Parser from 'html-react-parser';

/**
 * 
 * Displays Information on how to play Vision games
 * 
 */
export default class HowToPlay extends Component {
  constructor() {
    super();
    this.auth = new Auth();
    this.config = new Config();
    this.$http = this.config.DConnect();
    this.state = {
      works: {title:'', content:''},
      worksteps: [],
      reward: {title:'', content:''},
      rewardsteps: []
    };
  }

  componentDidMount = () => {
    this.getContent();
  }

  getContent = () => {
    this.$http.get('web/web/portal/4')
      .then(res => {
        let content = this.auth.decryptData(res.data);
        
        let works = content.filter(ct => ct.category===3 && ct.categorySub===0);
        let reward = content.filter(ct => ct.category==3 && ct.categorySub==2);
        let worksteps = content.filter(ct => ct.category===3 && ct.categorySub===1);
        let rewardsteps = content.filter(ct => ct.category==3 && ct.categorySub==3);

        //alert(JSON.stringify(news))
        this.setState({ works: works[0], reward: reward[0], rewardsteps, worksteps });
      }).catch((error) => { console.log(error.message) })
  }

  render() {
    let {works, reward, rewardsteps, worksteps} = this.state;

    return (
      <div>
        <section class="inner-page-banner has_bg_image" data-background="assets/images/inner-page-bg.jpg" style={{backgroundImage:'url(assets/images/inner-page-bg.jpg)'}}>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="inner-page-banner-area">
                  <h1 class="page-title">How to play</h1>
                  <nav aria-label="breadcrumb" class="page-header-breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="#">Home</a></li>
                      <li class="breadcrumb-item active">how to play</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section class="work-steps-section section-padding">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-7">
                <div class="section-header text-center">
                  <h2 class="section-title">{works.title}</h2>
                  {Parser(works.content)}
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="work-steps-items-part d-flex">
                  <div class="line"><img src="assets/images/elements/line.png" alt="line-image" /></div>
                  {worksteps.map((wstep,idx) => (
                    <div class="work-steps-item">
                      <div class="work-steps-item-inner">
                        <div class="icon">
                          <img src={`assets/images/svg-icons/how-work-icons/${idx+1}.svg`} alt="icon" />
                          <span class="count-num">01</span>
                        </div>
                        <h4 class="title">{wstep.title}</h4>
                        {Parser(wstep.content)}
                      </div>
                    </div>
                  ))}
                  
                </div>
              </div>
              <div class="col-lg-6">
                <div class="work-steps-thumb-part">
                  <img src="assets/images/elements/step.png" alt="work-step-image" />
                  <a href="#" class="play-btn"><i class="fa fa-play"></i></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="reward-section section-padding border-top has_bg_image" data-background="assets/images/bg-five.jpg" style={{backgroundImage:'url(assets/images/bg-five.jpg)'}}>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-7">
                <div class="section-header text-center">
                  <h2 class="section-title">{reward.title}</h2>
                  {Parser(reward.content)}
                </div>
              </div>
            </div>
            <div class="row m-bottom-not-30">
              {rewardsteps.map((rstep, idx) => (
                <div class="col-lg-4 col-md-6">
                  <div class="reward-item text-center">
                    <div class="icon">
                      <img src={`assets/images/svg-icons/reward/${idx+1}.svg`} alt="image" />
                    </div>
                    <div class="content">
                      <h3 class="title">{rstep.title}</h3>
                      {Parser(rstep.content)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    )
  }
}